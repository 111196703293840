import React from 'react';
import { Input } from 'antd';

const TextInput = ({ label, value, onChange, type = 'text' }) => (
    <div className={"form_group"}>
        <label>{label}:</label>
        <Input
            type={type}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    </div>
);

export default TextInput;