import {Button, Result} from "antd";
import {FrownOutlined} from "@ant-design/icons";
import React from "react";

const ErrorComponent = () => {
    return (
        <div style={{height: '100vh'}}>
            <Result
                icon={<FrownOutlined/>}
                title="Something went wrong!"
                subTitle=""
                extra={<Button
                    className={"primary_btn"}
                    onClick={()=>
                        window.location.reload()
                    }>Try Again</Button>}
            />
        </div>
    );
};

export default ErrorComponent;