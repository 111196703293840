import {Modal} from "antd";
import React from "react";

const DiscardChangesDialog = ({visible, onConfirm, onCancel}) => {
    return (<Modal
        title="Discard Changes"
        open={visible}
        onCancel={onCancel}
        onOk={onConfirm}
    >
        <p>Are you sure you want to discard changes?</p>
    </Modal>);
}

export default DiscardChangesDialog;