import {useRef, useState} from "react";
import {Input, Select, DatePicker, Upload, Image, Spin, Popconfirm} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import ReactQuill from "react-quill";
import dayjs from 'dayjs';
import {useDispatch} from "react-redux";
import CommonService from "../../utils/CommonService";

const {Option} = Select;
const CustomEditor = ({
                          beforeUpload,
                          type,
                          value,
                          onChange,
                          options,
                          handleAssetUpload,
                          fileList,
                          currentAttributeName,
                          productId,
                          getProductDetail
                      }) => {
  
    const dispatch = useDispatch();

    const reactQuillRef = useRef(null);
    const [shouldFocus, setShouldFocus] = useState(false);

    const handleTextChange = (e) => {
        if (shouldFocus) {
            onChange(e);

        }
    };

    const handleAssetDelete = (productId, currentAttributeName, assetLink) => {
        CommonService.handleAssetDelete(dispatch, productId, currentAttributeName, assetLink, getProductDetail);
    }


    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined/>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    switch (type) {
        case 'text':
            return <Input
                placeholder="Enter a value"
                rootClassName={"custom-input-field"} value={value} onChange={(e) => onChange(e.target.value)}/>;
        case 'number':
            return <Input
                placeholder="Enter a value"
                rootClassName={"custom-input-field"} type="number" value={value}
                onChange={(e) => onChange(parseFloat(e.target.value))}/>;

        case 'url':
        case 'completion':
            return <Input
                placeholder="Enter a value"
                rootClassName={"custom-input-field"} value={value} onChange={(e) => onChange(e.target.value)}/>;
        case 'dropdown':
            return (
                <Select className={"single_select"}
                        showSearch={true}
                        placeholder="Select a option"
                        value={value ? value : undefined} onChange={onChange}>
                    {/* Provide options for dropdown or multiselect based on your data */}
                    <Option key={-1} value={null}></Option>
                    {options?.map((val, index) => <Option key={index} value={val}>{val}</Option>)}
                </Select>
            )
        case 'multiselect':
            return (
                <Select
                    placeholder="Select multiple options"
                    className={"custom_multiselct"} mode={type === 'multiselect' ? 'multiple' : 'default'}
                    value={value ? value : undefined} onChange={onChange}>
                    {/* Provide options for dropdown or multiselect based on your data */}

                    {Array.isArray(options) &&
                        (Array.isArray(options[0]) ? (
                            // Array of arrays
                            options.map((groupOptions, groupIndex) =>
                                groupOptions.map((option, optionIndex) => (
                                    <Option key={`${groupIndex}-${optionIndex}`} value={option}>
                                        {option}
                                    </Option>
                                ))
                            )
                        ) : (
                            // Flat array
                            options.map((option, index) => (
                                <Option key={index} value={option}>
                                    {option}
                                </Option>
                            ))
                        ))}
                </Select>
            );
        case 'date':
            return (
                <DatePicker
                    placeholder="Select Date"

                    value={ dayjs(value).isValid()?dayjs(value) : null}
                    onChange={(date, dateString) => onChange(dateString?dateString:"No date Specified")}
                    className="custom_datepicker"
                    popupClassName={"custom_datepicker"}
                />
            );
        case 'boolean':
            return (
                <Select className={"single_select"}
                        placeholder="Select a option"
                        value={
                            (value===false || value===true)?value === 'true' || value === true ? "True" : "False" : null
                        } onChange={onChange}>
                    <Select.Option value={null}> </Select.Option>
                    <Select.Option value={true}>True</Select.Option>
                    <Select.Option value={false}>False</Select.Option>
                </Select>
            );
        case 'media':
            return (
                <div className={"upload_image_wrapper"}>
                    {Array.isArray(value) && value && value.length > 0 && value.map((val, index) => (
                        <div className={"uploaded_img_sec"} key={index}>
                            <div>
                                {val.type === 'image' &&
                                    <>
                                        <div className="image-container">
                                            <div className="uploaded_img_sec">
                                                <Image
                                                    src={val.link}
                                                    alt="image"
                                                    width={126}
                                                    height={126}
                                                />
                                                <Popconfirm overlayClassName={"custom_popover_wraper"}
                                                            title={"Are you sure you want to delete this asset?"}
                                                            onConfirm={() => handleAssetDelete(
                                                                productId,
                                                                currentAttributeName,
                                                                val?.link
                                                            )}>
                                                    <div className="delete-overlay">
                                                        <DeleteOutlined className="delete-icon"/>

                                                    </div>
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    </>

                                }
                                {val.type === 'video' &&
                                    <div className={"uploaded_img_sec"}>
                                        <video height={126} width={126} src={val.link} controls/>
                                        <Popconfirm title={"Are you sure you want to delete this asset?"}
                                                    onConfirm={() => handleAssetDelete(
                                                        productId,
                                                        currentAttributeName,
                                                        val?.link
                                                    )}>
                                            <div className="delete-overlay">
                                                <DeleteOutlined className="delete-icon"/>
                                            </div>
                                        </Popconfirm>

                                    </div>
                                }
                                {val.type !== 'image' && val.type !== 'video' && (
                                    <div className="image-container">
                                        <div className="uploaded_img_sec">

                                            <Image
                                                src={val?.link}
                                                alt="image"
                                                width={126}
                                                height={126}
                                            />
                                            <Popconfirm overlayClassName={"custom_popover_wraper"}
                                                        title={"Are you sure you want to delete this asset?"}
                                                        onConfirm={() => handleAssetDelete(
                                                            productId,
                                                            currentAttributeName,
                                                            val?.link
                                                        )}>
                                                <div className="delete-overlay">
                                                    <DeleteOutlined className="delete-icon"/>
                                                </div>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {!Array.isArray(value) && value && (

                        <div className={"uploaded_img_sec"}>
                            <Image
                                fileList={fileList}
                                src={value} alt="image" width={126} height={126}/>

                        </div>
                    )}


                    {fileList.length > 0 && (
                        fileList.map((val, index) => (
                            <div className={"uploaded_img_sec"} key={index}>
                                <div>
                                    {val?.type.startsWith('image/') &&
                                        <Spin spinning={true}><Image src={val.url} alt="image" width={126}
                                                                     height={126}/></Spin>}
                                    {val?.type.startsWith('video/') && <Spin spinning={true}>
                                        <video height={126} width={126} src={val.url} controls/>
                                    </Spin>}
                                    {val?.type.startsWith('video/') && val.type.startsWith('image/') && (
                                        <Spin spinning={true}> <a href={val.url} target="_blank" rel="noreferrer">
                                            {val.url}
                                        </a></Spin>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                    <div className={"uploaded_btn_wrapper"}>
                        <Upload
                            fileList={fileList}
                            multiple={true}
                            accept="image/*,video/*"
                            listType="picture-card"
                            showUploadList={false}
                            onChange={(info) => handleAssetUpload(info, currentAttributeName, productId)}
                            beforeUpload={beforeUpload} // Prevent automatic upload, handle it in your submit function
                        >
                            {uploadButton}

                        </Upload>

                    </div>
                </div>
            );

        case 'html':
            return (
                <ReactQuill
                    ref={(el) => {
                        reactQuillRef.current = el;
                    }}
                    value={value ? value : ''}
                    onChange={handleTextChange}
                    onFocus={() => setShouldFocus(true)}
                />
            );
        default:
            return <Input
                placeholder="Enter a value"
                rootClassName={"custom-input-field"} value={value} onChange={(e) => onChange(e.target.value)}/>;
    }
};

export default CustomEditor;
