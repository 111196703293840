export const LIST_VIEW = 'view/listView'
export const ADD_VIEW = 'view/addView'
export const EDIT_VIEW = 'view/editView'
export const DELETE_VIEW = 'view/deleteView'
export const SET_DEFAULT_VIEW = 'view/setDefaultView'

export const LIST_VIEWT_FULFILLED = 'view/listView/fulfilled'
export const ADD_VIEWT_FULFILLED = 'view/addView/fulfilled'
export const EDIT_VIEWT_FULFILLED = 'view/editView/fulfilled'
export const DELETE_VIEWT_FULFILLED = 'view/deleteView/fulfilled'
export const SET_DEFAULT_VIEWT_FULFILLED = 'view/setDefaultView/fulfilled'

export const LIST_VIEW_PENDING = 'view/listView/pending'
export const ADD_VIEW_PENDING = 'view/addView/pending'
export const EDIT_VIEW_PENDING = 'view/editView/pending'
export const DELETE_VIEW_PENDING = 'view/deleteView/pending'
export const SET_DEFAULT_VIEW_PENDING = 'view/setDefaultView/pending'

export const LIST_VIEW_REJECTED = 'view/listView/rejected'
export const ADD_VIEW_REJECTED = 'view/addView/rejected'
export const EDIT_VIEW_REJECTED = 'view/editView/rejected'
export const DELETE_VIEW_REJECTED = 'view/deleteView/rejected'
export const SET_DEFAULT_VIEW_REJECTED = 'view/setDefaultView/rejected'




