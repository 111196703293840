
import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncAction} from "../common/AsyncCases";
import CategoryThunk from "../thunk/CategoryThunk";

const initialState = {
    categories: [],
    isLoading: false,
    error: null,
};

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        resetError: (state) => {
            state.error = null;
        },
        resetCategoryState: (state) => {
            state.categories = [];
            state.isLoading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        handleAsyncAction(builder, CategoryThunk.categoryList, (state, action) => {
            state.categories = action.payload.data.categoryList;
            }
        );

        handleAsyncAction(builder, CategoryThunk.categoryAdd, (state, action) => {
            state.categories = [action.payload.data.categoryList, ...state.categories];
        });

        handleAsyncAction(builder, CategoryThunk.categoryDelete, (state, action) => {
            // const index = state.categories.findIndex((category) => category.id === action.payload.data.categoryId);
            // state.categories.splice(index, 1);
        });

        handleAsyncAction(builder, CategoryThunk.categoryDetails, (state, action) => {
        });

        handleAsyncAction(builder, CategoryThunk.subCategoryAdd, (state, action) => {
            state.categories = [action.payload.data.subCategoryData, ...state.categories];
        });

    }
});

export const {resetCategoryState} = categorySlice.actions;

export default categorySlice.reducer;
