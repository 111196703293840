import React, {useState} from "react";
import {Button, Checkbox, Form, Input, Modal} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const EditAttributeGroupModal = ({ visible, onCancel, onOk, selectedGroup = { label: '', attributes: [] }, attributes = [], searchedAttributes}) => {
    const [editedLabel, setEditedLabel] = useState(selectedGroup.labelToShow);
    const [editedAttributes, setEditedAttributes] = useState(
        Array.isArray(selectedGroup.attributes) ? selectedGroup.attributes.map((attr) => attr.id) : []
    );

    const handleLabelChange = (value) => {
        setEditedLabel(value);
    };

    const handleAttributeSelect = (attributeId) => {
        setEditedAttributes((prevSelected) => [...prevSelected, attributeId]);
    };

    const handleAttributeRemove = (attributeId) => {
        setEditedAttributes((prevSelected) =>
            prevSelected.filter((id) => id !== attributeId)
        );
    };

    return (
        <Modal
            title={`Edit Attribute Group - ${selectedGroup?.label ? selectedGroup.label : ''}`}
            open={visible}
            onCancel={onCancel}
            className={"custom_modal edit_attribute_modal"}
            footer={[
                <Button className={"secondary_btn"}
                        onClick={onCancel}
                        key="1">Cancel</Button>,
                <Button className={"primary_btn"}
                        onClick={()=>onOk(editedLabel, editedAttributes)}
                        key="2">Save</Button>,
            ]}
        >
            <Form layout={"vertical"} className={"custom_form_area"}>
                <Form.Item label="Edit Label">
                    <Input
                        value={editedLabel}
                        onChange={(e) => handleLabelChange(e.target.value)}
                    />
                </Form.Item>
                <div className={"available_attribute_wrapper"}>
                <div className={"column_filter_wrapper"} style={{width:'100%'}}>
                    <div className={"body_content"}>
                        <div className={"left_col"}>
                            <h3>Available Attributes</h3>
                            <div className={"inner_listing"}>
                                {searchedAttributes.map((attribute) => (
                                    <div className={"checkbox_card"} key={attribute?.id}>
                                        <Checkbox
                                            checked={editedAttributes.includes(attribute?.id)}
                                            onChange={() => {
                                                if (editedAttributes.includes(attribute?.id)) {
                                                    handleAttributeRemove(attribute?.id);
                                                } else {
                                                    handleAttributeSelect(attribute?.id);
                                                }
                                            }}
                                        />

                                        {attribute?.nameToShow} - {attribute?.type}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={"divider"}></div>
                        <div className={"right_col"}>
                            <h3>Selected {editedAttributes?.length > 1 ? "Attributes" : "Attribute"} ({editedAttributes?.length})</h3>

                            <div className={"inner_listing scroll_ui"}>
                                {editedAttributes.map((selectedId) => {
                                    const selectedAttribute = attributes.find(
                                        (attr) => attr?.id === selectedId
                                    );
                                    return (

                                        <div
                                            key={selectedAttribute?.id}
                                            style={{ marginBottom: '8px' }}
                                            className={"delete_card"}
                                        >
                                            {selectedAttribute?.nameToShow} - {selectedAttribute?.type}
                                            <Button
                                                icon={<CloseCircleOutlined />}
                                                className={"delete_btn"}
                                                onClick={() =>
                                                    handleAttributeRemove(selectedAttribute?.id)
                                                }
                                            ></Button>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default EditAttributeGroupModal;
