import {createAsyncThunk} from '@reduxjs/toolkit';
import staffService from "../../redux/actions/StaffAction";
import {FETCH_STAFF_LIST,CREATE_STAFF,EDIT_STAFF,DELETE_STAFF,RESET_PASSWORD} from '../types/StaffActionTypes'

export const fetchStaffList = createAsyncThunk(FETCH_STAFF_LIST, async (body) => {
    try{
        const response = await staffService.fetchStaffList(body);
        return response?.data?.data;
    }catch (e) {
        throw e?.response?.data || e.message;
    }
});

export const createStaff = createAsyncThunk(CREATE_STAFF, async (newStaff) => {
   try{
       const response = await staffService.createStaff(newStaff);
       return response.data;
   }catch (e) {
       throw e?.response?.data || e.message;
   }
});

export const editStaff = createAsyncThunk(EDIT_STAFF, async (editedStaff) => {
    try{
        const response = await staffService.editStaff(editedStaff);
        return response.status === 200 ? editedStaff : response;
    }catch (e) {
        throw e?.response?.data || e.message;
    }
});

export const deleteStaff = createAsyncThunk(DELETE_STAFF, async (staffId) => {
    try{
        await staffService.deleteStaff(staffId);
        return staffId;
    }catch (e) {
        throw e?.response?.data || e.message;
    }

});

export const resetPasswordStaff = createAsyncThunk(RESET_PASSWORD, async (staff) => {
    try{
        await staffService.resetPasswrord(staff);
        return staff;
    }catch (e) {
        throw e?.response?.data || e.message;
    }
});