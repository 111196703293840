import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Toast from "../../components/Notification/Toast";
import {loginUser} from '../../redux/thunk/UserThunk';
import {Link, useNavigate} from 'react-router-dom';
import {resetError} from "../../redux/slices/UserSlice";
import * as Yup from 'yup';
import AuthForm from "../../components/Form/AuthForm";
import {LOGIN_FULFILLED} from "../../redux/types/UserActionTypes"
const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginFormFields = [
        { name: 'email', type: 'email', placeholder: 'Email' },
        { name: 'password', type: 'password', placeholder: 'Password' },
    ];
    const loginValidationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Required').min(4),
    });

    useEffect(() => {
        dispatch(resetError());
    }, [dispatch]);

    const handleSubmit = async (values, {setSubmitting}) => {
        try {
            const payload = await dispatch(loginUser(values));
            if (payload.type === LOGIN_FULFILLED) {
                Toast.successNotification('Login Succesfully');
                setTimeout(() => {
                    navigate('/product/list');
                }, 2000);

            } else {
                Toast.errorNotification(payload.error.message);
            }
            setSubmitting(false);

        } catch (error) {
            // Handle errors
            Toast.errorNotification(error);
            console.log('An error occurred during form submission:', error);
            setSubmitting(false);
        }
    };

    return (
        <div className="loginpage_wrapper">

            <div className="login_bg">
            </div>
            <div className="inner-container container-column">
                <div className="logo_wrap">PIM</div>
                <div className="whitebg_wrap">
                    <h4>Welcome Back</h4>
                    <p>Enter your credentials to access your account</p>
                    <div>
                        <AuthForm
                            initialValues={{ email: '', password: '' }}
                            validationSchema={loginValidationSchema}
                            onSubmit={handleSubmit}
                            fields={loginFormFields}
                            buttonText="Login"
                        />
                    </div>
                </div>
                <p className="login-note" style={{textAlign: 'center'}}>
                    Don't have an account? <Link to="/register">Register</Link>
                </p>
            </div>
        </div>
    );
};

export default Login;
