import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, InputNumber, Form } from 'antd';

const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          onClick,
                          ...restProps
                      }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    const cellRef = useRef(null);

    useEffect(() => {
        if (editing) {
            const handleDocumentClick = (e) => {
                if (cellRef.current && !cellRef.current.contains(e.target)) {
                    onClick();
                }
            };

            document.addEventListener('click', handleDocumentClick);

            return () => {
                document.removeEventListener('click', handleDocumentClick);
            };
        }
    }, [editing, onClick]);

    return (
        <td ref={cellRef} {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                <div onClick={onClick}>{children}</div>
            )}
        </td>
    );
};

const EditableTable = () => {
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    const [data] = useState([
        { key: '1', name: 'John Doe', age: 32, address: 'New York' },
        { key: '2', name: 'Jane Doe', age: 28, address: 'San Francisco' },
    ]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: '25%',
            editable: true,
        },
        {
            title: 'Age',
            dataIndex: 'age',
            width: '15%',
            editable: true,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            width: '40%',
            editable: true,
        },
    ];

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    }


    // TODO: save function
    // const save = async (record) => {
    //     try {
    //         const row = await form.validateFields();
    //         const newData = [...data];
    //         const index = newData.findIndex((item) => record.key === item.key);
    //
    //         if (index > -1) {
    //             const item = newData[index];
    //             Object.keys(row).forEach((key) => {
    //                 item[key] = row[key];
    //             });
    //             setData(newData);
    //             setEditingKey('');
    //         }
    //     } catch (errInfo) {
    //         console.log('Validate Failed:', errInfo);
    //     }
    // };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                onClick: () => edit(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
            />
        </Form>
    );
};

export default EditableTable;
