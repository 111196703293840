import React from 'react';
import { Tabs } from 'antd';
import StaffHome from "./StaffHome";
import StaffList from "./StaffList"

const AccountDashboard = () => {
    return (
        <div className="detail_right_wrapper detail_custom_width">
            <div className="top_tabbar">
                <Tabs defaultActiveKey="1" rootClassName="custom_tabs"
                 items={[
                    {
                        label:`Account`,
                        key: "1",
                        children: <StaffHome />
                    },
                    {
                        label: `Teams`,
                        key: "2",
                        children: <StaffList />
                    }
                 ]}
                />
            </div>
        </div>
    )
};

export default AccountDashboard;