import {createAsyncThunk} from '@reduxjs/toolkit';
import AttributesService from "../actions/AttributesAction";
import {FETCH_ATTRIBUTES,ADD_ATTRIBUTE,UPDATE_ATTRIBUTE,FETCH_ATTRIBUTE_DETAILS,DELETE_ATTRIBUTE,
    ATTRIBUTE_GROUP_ADD,ATTRIBUTE_GROUP_UPDATE,ATTRIBUTE_GROUP_LIST,ATTRIBUTE_GROUP_DELETE} 
    from '../types/AttributeActionTypes'

const AttributeThunk = {

    fetchAttributes : createAsyncThunk(FETCH_ATTRIBUTES, async () => {
        try{
            const response = await AttributesService.getAttributes();
            return response?.data?.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }

    }),

    addAttribute : createAsyncThunk(ADD_ATTRIBUTE, async (newAttribute) => {
        try{
            const response = await AttributesService.addAttribute(newAttribute);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),
    updateAttribute : createAsyncThunk(UPDATE_ATTRIBUTE, async (attribute) => {
        try{
            const response = await AttributesService.updateAttribute(attribute);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    fetchAtrributeDetails : createAsyncThunk(FETCH_ATTRIBUTE_DETAILS, async (attributeId) => {
        try{
            const response = await AttributesService.fetchAtrributeDetails(attributeId);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    deleteAttribute : createAsyncThunk(DELETE_ATTRIBUTE, async (attributeId) => {
        try{
            const response = await AttributesService.deleteAttribute(attributeId);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    attributeGroupAdd : createAsyncThunk(ATTRIBUTE_GROUP_ADD, async (newAttributeGroup) => {
        try{
            const response = await AttributesService.attributeGroupAdd(newAttributeGroup);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    attributeGroupUpdate : createAsyncThunk(ATTRIBUTE_GROUP_UPDATE, async (attributeGroup) => {
        try{
            const response = await AttributesService.attributeGroupUpdate(attributeGroup);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    attrbuteGroupList : createAsyncThunk(ATTRIBUTE_GROUP_LIST, async () => {
        try{
            const response = await AttributesService.attrbuteGroupList();
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    attributeGroupDelete : createAsyncThunk(ATTRIBUTE_GROUP_DELETE, async (attributeGroupId) => {
        try{
            const response = await AttributesService.attributeGroupDelete(attributeGroupId);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),
};

export default AttributeThunk;
