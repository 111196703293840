import {createSlice} from '@reduxjs/toolkit';
import ProductThunk from "../thunk/ProductThunk";
import {handleAsyncAction} from "../common/AsyncCases";

const initialState = {
    products: [],
    isLoading: false,
    error: null,
    isCsvImported: false,
    parentCount: 0,
    variantsCount: 0,
    count: 0,
    singleProduct: null,
    skip: 1,
    limit: 25,
    filters:null,
    productDetail:null,
    filterViewName:null,
    defaultView:true
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        resetError: (state) => {
            state.error = null;
        },
        resetProductState: (state) => {
            state.products = [];
            state.isLoading = false;
            state.error = null;
            state.isCsvImported = false;
            state.parentCount = 0;
            state.variantsCount = 0;
            state.count = 0;
            state.singleProduct = null;
            state.skip = 1;
            state.limit = 25;
            state.filters=[];
            state.productDetail=null;
            state.defaultView=true;
            state.filterViewName=null;
        },
        setFilterViewName:(state,payload)=>{
            state.filterViewName=payload.payload;
        },
        setDefaultView:(state,payload)=>{
            state.defaultView=payload.payload;
        }
    },
    extraReducers: (builder) => {
        handleAsyncAction(builder, ProductThunk.fetchProducts, (state, action) => {
            state.products = action.payload;

        });


        handleAsyncAction(builder, ProductThunk.addProduct, (state, action) => {
        });

        handleAsyncAction(builder, ProductThunk.importProductCsv, (state, action) => {
            state.isCsvImported=true;
        });

        handleAsyncAction(builder, ProductThunk.productList, (state, action) => {
            state.products = action.payload?.data?.productList;
            state.parentCount = action.payload?.data?.count;
            state.variantsCount = action.payload?.data?.variantCount;
            state.count = action.payload?.data?.count;
            state.singleProduct = action.payload.data.productList[0];
            state.skip = action.meta.arg.skip ;
             state.limit = action.meta.arg.limit;
             state.filters=action.meta?.arg?.filters?action.meta?.arg?.filters:state.filters
            state.defaultView=(action.meta?.arg?.defaultView || action.meta?.arg?.defaultView===false)?action.meta?.arg?.defaultView:true
            state.isCsvImported = false;

        });

        handleAsyncAction(builder, ProductThunk.productDetail, (state, action) => {
            state.productDetail=action?.payload?.data?.productData;
            // state.isCsvImported = false;
            // resetProductState(state);
            // state.products = action.payload;
        });

        handleAsyncAction(builder, ProductThunk.productSkuValidation, (state, action) => {
            // state.products = action.payload;
        });

        handleAsyncAction(builder, ProductThunk.variantUnlink, (state, action) => {
            // state.products = action.payload;
        });

        handleAsyncAction(builder, ProductThunk.productDelete, (state, action) => {
            // state.products = action.payload;
        });

        handleAsyncAction(builder, ProductThunk.productEdit, (state, action) => {
            // state.products = action.payload;
        });

        handleAsyncAction(builder, ProductThunk.editColumnsProductList, (state, action) => {
        });

        handleAsyncAction(builder, ProductThunk.productBulkDelete, (state, action) => {
        });

        handleAsyncAction(builder, ProductThunk.productAssetUpload, (state, action) => {
        });

        handleAsyncAction(builder, ProductThunk.prodctAssetDelete, (state, action) => {
        });

        handleAsyncAction(builder, ProductThunk.productFilterSave, (state, action) => {

        });

    }
});

export const {resetError,resetProductState,setFilterViewName,setDefaultView} = productSlice.actions;
export default productSlice.reducer;



