export const REGISTER = 'user/register'
export const LOGIN = 'user/login'
export const LOGOUT = 'user/logout'
export const ACCESS_TOKEN_LOGIN = 'user/accessTokenLogin'
export const VALIDATE_KEY = 'user/validateKey'
export const RESET_PASSWORD = 'user/resetPassword'

export const REGISTER_FULFILLED = 'user/register/fulfilled'
export const LOGIN_FULFILLED = 'user/login/fulfilled'
export const LOGOUT_FULFILLED = 'user/logout/fulfilled'
export const ACCESS_TOKEN_LOGIN_FULFILLED = 'user/accessTokenLogin/fulfilled'
export const VALIDATE_KEY_FULFILLED = 'user/validateKey/fulfilled'
export const RESET_PASSWORD_FULFILLED = 'user/resetPassword/fulfilled'

export const REGISTER_PENDING = 'user/register/pending'
export const LOGIN_PENDING = 'user/login/pending'
export const LOGOUT_PENDING = 'user/logout/pending'
export const ACCESS_TOKEN_LOGIN_PENDING = 'user/accessTokenLogin/pending'
export const VALIDATE_KEY_PENDING = 'user/validateKey/pending'
export const RESET_PASSWORD_PENDING = 'user/resetPassword/pending'

export const REGISTER_REJECTED = 'user/register/rejected'
export const LOGIN_REJECTED = 'user/login/rejected'
export const LOGOUT_REJECTED = 'user/logout/rejected'
export const ACCESS_TOKEN_LOGIN_REJECTED = 'user/accessTokenLogin/rejected'
export const VALIDATE_KEY_REJECTED = 'user/validateKey/rejected'
export const RESET_PASSWORD_REJECTED = 'user/resetPassword/rejected'