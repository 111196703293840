// UserSlice.js
import {createSlice} from '@reduxjs/toolkit';
import {accessTokenLoginUser, registerUser, loginUser, logoutUser, validateKey ,resetPassword} from "../thunk/UserThunk";
import {handleAsyncAction} from "../common/AsyncCases";

const initialUser = (localStorage.getItem('user') && localStorage.getItem('user') !== "undefined") ? JSON.parse(localStorage.getItem('user'))
    : null;

const slice = createSlice({
    name: 'user',
    initialState: {
        user: initialUser,
        token: localStorage.getItem('token') ?? null,
        isLoading: false,
        error: null,
        isSubmitted: false,
        isVerified: null,
        isPasswordSubmitted: false,

    },
    reducers: {
        resetError: (state) => {
            state.error = null;
            state.isVerified = null;
            // state.isSubmitted = false;
        },
        resetSubmitted: (state) => {
            state.isSubmitted = false;
        }
    },
    extraReducers: (builder) => {
        handleAsyncAction(builder, registerUser, (state, action) => {
            state.isSubmitted = true;
        });

        handleAsyncAction(builder, loginUser, (state, action) => {
            if (action.payload.token) {
                state.user = action.payload;
                localStorage.setItem('user', JSON.stringify(action.payload));
                localStorage.setItem('token', action.payload.token);
            }
        });

        handleAsyncAction(builder, logoutUser, (state) => {
            state.user = null;
            state.isVerified = null;
            state.isSubmitted = false;
            state.isPasswordSubmitted = false;
            state.error = null;
            state.isLoading = false;
            localStorage.removeItem('user');
            localStorage.removeItem('token');
        });

        handleAsyncAction(builder, accessTokenLoginUser, (state, action) => {
            if (action.payload && action.payload.token) {
                state.user = action.payload;
                localStorage.setItem('user', JSON.stringify(action.payload));
                localStorage.setItem('token', action.payload.token);
            }else{
                state.user = null;
                localStorage.removeItem('user');
                localStorage.removeItem('token');
            }
        });

        handleAsyncAction(builder, validateKey, (state, action) => {
            state.isVerified = action.payload?.isKeyValid;
            state.error = action.payload?.message;
        });

        handleAsyncAction(builder, resetPassword, (state, action) => {
            state.isPasswordSubmitted = true;
            if (action.payload && action.payload.token) {
                state.user = action.payload;
                localStorage.setItem('user', JSON.stringify(action.payload));
                localStorage.setItem('token', action.payload.token);
            }
        });

    },
});

export const {loginSuccess, logoutSuccess , resetError,resetSubmitted} = slice.actions;
export default slice.reducer;
