import api from '../../config/api';

const viewService = {

    addView: async (view) => {
        return await api.post('/view/add', {...view});
    },

    editView: async (view) => {
        return await api.post('/view/edit', {...view});
    },

    listView: async () => {
        return await api.post('/view/list');
    },

    setDefaultView: async (view) => {
        return await api.post('/default/view/set', {...view});
    },

    deleteView: async (view) => {
        return await api.delete('/view/delete', {...view});
    }
};

export default viewService;