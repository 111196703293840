import {message} from "antd";
import ProductThunk from "../redux/thunk/ProductThunk";
import {PRODUCT_ASSET_DELETE_FULFILLED} from "../redux/types/ProductActionTypes";

const CommonService = {
    handleAssetDelete: async (dispatch, productId, attributeName, assetLink, getProductDetails) => {
        if (!assetLink) {
            return;
        }

        const body = {
            productId,
            attributeName,
            assetLink,
        };

        const payload = await dispatch(ProductThunk.prodctAssetDelete(body));

        if (payload.type === PRODUCT_ASSET_DELETE_FULFILLED) {
            await getProductDetails(); // Assuming this function is available
            if (attributeName === "Thumbnail") {
                message.success(`Thumbnail Deleted Successfully`);
            } else {
                message.success(`Asset Deleted Successfully`);
            }

        } else {
            message.error(`${attributeName} Deletion Failed`);
        }
    },
    handleAttributeSize:(val)=>{
        if(val?.selectOption?.length>500){
            val.selectOption=val.selectOption.slice(0,500)
        }
        if(val?.type==='boolean'){
            val.selectOption=['True','False']
        } else if(val?.type==='dropdown'){

            val.selectOption=Array.from(new Set(val.selectOption))
        }
        return val;
    }
};

export default CommonService;
