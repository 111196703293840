import React, {useState, useEffect} from "react";
import {Menu, Layout} from "antd";

import {useWindowWidth} from "@react-hook/window-size";
import {
    ProfileTwoTone,
} from "@ant-design/icons";
import { NavLink} from "react-router-dom";

const {Sider} = Layout;

const StaffNav = () => {
    // state
    const [collapsed, setCollapsed] = useState(false);
    const [current, setCurrent] = useState("");
    // hooks
    const onlyWidth = useWindowWidth();

    useEffect(() => {
        setCurrent(window.location.pathname);
    }, []);

    useEffect(() => {
        if (onlyWidth < 800) {
            setCollapsed(true);
        } else if (onlyWidth > 800) {
            setCollapsed(false);
        }
    }, [onlyWidth]);

    const activeName = (name) => `${current === name && "active"}`;

    return (
        <Sider
            className="profile_sidebar"
            collapsible
            collapsed={collapsed}
            width={'200px'}
            trigger={null}
            onCollapse={() => setCollapsed(!collapsed)}
        >
            <p className="sidebar_titile">PROFILE AND ACCOUNT</p>
            <Menu
                // defaultSelectedKeys={["1"]}
                defaultOpenKeys={["2", "6", "10"]}
                mode="inline"
                collapsed={collapsed.toString()}
                items={[
                    {   
                        label:(
                            <NavLink className={({isActive}) => isActive ? 'active' : ''} to="/account">
                                <span className={activeName("/account")}>Profile</span>
                            </NavLink>
                        ),
                        key:"1",
                        icon: <ProfileTwoTone/>
                    }
                ]}
            />
        </Sider>
    );
};

export default StaffNav;
