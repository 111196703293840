import CategoryService from "../actions/CategoryAction";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {CATEGORY_ADD,CATEGORY_LIST,CATEGORY_DELETE,CATEGORY_DETAILS,SUB_CATEGORY_ADD} from '../types/CategoryActionTypes'

const CategoryThunk = {

    categoryAdd: createAsyncThunk(CATEGORY_ADD, async (newCategory) => {
        try {
            const response = await CategoryService.addCategory(newCategory);
            return response.data;
        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    categoryList: createAsyncThunk(CATEGORY_LIST, async () => {
        try {
            const response = await CategoryService.getCategoriesList();
            return response.data;
        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    categoryDelete: createAsyncThunk(CATEGORY_DELETE, async (categoryId) => {
        try {
            const response = await CategoryService.deleteCategory(categoryId);
            return response.data;
        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    categoryDetails: createAsyncThunk(CATEGORY_DETAILS, async (categoryId) => {
        try {
            const response = await CategoryService.fetchCategoryDetails(categoryId);
            return response.data;
        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),


    subCategoryAdd: createAsyncThunk(SUB_CATEGORY_ADD, async (newSubCategory) => {
        try {
            const response = await CategoryService.subCategoryAdd(newSubCategory);
            return response.data;
        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),


};

export default CategoryThunk;