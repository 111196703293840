import { combineReducers } from 'redux'
import UserSlice from '../slices/UserSlice'
import StaffSlice from "../slices/StaffSlice";
import AttributesSlice from "../slices/AttributesSlice";
import ProductSlice from "../slices/ProductSlice";
import CategorySlice from "../slices/CategorySlice";
import viewSlice from "../slices/ViewSlice";
// Main Reducer
const reducer = combineReducers({
    // here we will be adding reducers
    user: UserSlice,
    staff: StaffSlice,
    attributes: AttributesSlice,
    products: ProductSlice,
    categories: CategorySlice,
    view:viewSlice
});

export default reducer;