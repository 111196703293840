import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import { Typography } from 'antd'
import {useDispatch} from "react-redux";
import {accessTokenLoginUser} from "../../../redux/thunk/UserThunk";
import {resetError} from "../../../redux/slices/UserSlice";

const { Paragraph,Text } = Typography;

const StaffHome = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const labelStyle = { fontWeight: 'bold', marginRight: '8px' };
    
    useEffect(() => {
        // Dispatch the resetError action when the component mounts
        dispatch(resetError());
        dispatch(accessTokenLoginUser())
    }, [dispatch]);

    return (
        <div>
            <div className="staff_detail">
                <div className={"data-field"}>
                    <div className={"data-field-inner account_icon_section"}>
                        <div className="profile_img_blk">
                            <span className="icon">
                             <img src="/images/user-placeholder.jpeg" alt="profile"/>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"data-field_desc"}>
                    <div className={"data-field-inner account_desc_section"}>
                        <div className="detail_card" title="User Information">
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div className={"form_group"} style={{display: 'flex', marginBottom: '0px'}}>
                                    <Text className={"label_ui"} style={labelStyle}>Name:</Text>
                                    <Paragraph>{user.staff.name}</Paragraph>
                                </div>
                                <div className={"form_group"} style={{display: 'flex', marginBottom: '0px'}}>
                                    <Text className={"label_ui"} style={labelStyle}>Company Name:</Text>
                                    <Paragraph>{user.staff.companyName}</Paragraph>
                                </div>
                                <div className={"form_group"} style={{display: 'flex', marginBottom: '0px'}}>
                                    <Text className={"label_ui"} style={labelStyle}>Email:</Text>
                                    <Paragraph>{user.staff.email}</Paragraph>
                                </div>
                                <div className={"form_group"} style={{display: 'flex', marginBottom: '0px'}}>
                                    <Text className={"label_ui"} style={labelStyle}>Secret Key:</Text>
                                    <Paragraph className={"copy_ui"} copyable={{
                                        text: user.staff.secretKey,
                                        tooltips: ['Copy to Clipboard', 'Copied!']
                                    }}>**************</Paragraph>
                                </div>
                                <div className={"form_group"} style={{display: 'flex', marginBottom: '0px'}}>
                                    <Text className={"label_ui"} style={labelStyle}>Role:</Text>
                                    <Paragraph>{user.staff.roleType}</Paragraph>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffHome;
