import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {accessTokenLoginUser} from "../../../redux/thunk/UserThunk";
import {Layout} from "antd";
import {useNavigate} from "react-router-dom";
const {Content} = Layout;

const StaffPrivateRoute = ({component: Component, ...rest}) => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const controller = new AbortController();
            const signal = controller.signal;
            try {
                // Make the fetch request without checking the user state
                await dispatch(accessTokenLoginUser({ signal }));
            } catch (error) {
                // Handle errors (e.g., cancelled fetch due to component unmount)
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted due to component unmount.');
                } else {
                    console.error('An error occurred:', error);
                }
            } finally {
                // Cleanup logic
                controller.abort();
            }
        };

        fetchData();

        // Cleanup logic when component unmounts
        return () => {
            // Abort the fetch if the component is unmounted
            // controller.abort();
        };
    }, [dispatch]);


    useEffect(() => {
        // Logout if user is null
        if (user === null) {
            navigate("/login");
        }

        return () => {
            // Cleanup logic
        };
    }, [user, navigate]);

    return (
        <Layout>
            {/*<StaffNav/>*/}
            <Content
                style={{
                    margin: '16px 16px',
                    // overflow: 'auto',
                    height: '100vh',
                    marginTop: 54,
                }}
            >
                {user ? <Component/> : <h1>Not Authorized</h1>}
            </Content>

        </Layout>
    );
};
export default StaffPrivateRoute;
