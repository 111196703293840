import {Button, Modal, Form, Input, Select} from "antd";
import React from "react";
import {useSelector} from "react-redux";
import Toast from "../Notification/Toast";


const FilterModal = ({ handleClose, handleSaveFilter, states, setStates}) => {
    const {views}=useSelector(state=>state.view);
    const {count}=useSelector(state=>state.products);
    const handleFilterChange = (e) => {
        setStates(prevVal=> ({...prevVal, filterName: e.target.value}));
    }

    const handleFilterSubmit = () => {
        if(!states.modify && !states.filterName && states.filterName!==0){
            Toast.errorNotification("Name is required!");
            return;
        }
        if(states.modify && !states.selectedFilterName && states.selectedFilterName!==0){
            Toast.errorNotification("Existing Filter is required!");
            return;
        }
        setStates(prevState=>({...prevState,secondScreen: false}));
        handleSaveFilter();
        handleClose();
    }

    return (
        <Modal
            className={"load_filter_modal"}
            title={states.filterModalType==='list'?<h2>Load Filter</h2>:<h2>Save Filter</h2>}
            open={states.openFilterModal}
            onCancel={handleClose}
            footer={[
                <Button key="cancel" className="secondary_btn btn_size" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" className="primary_btn btn_size" onClick={handleFilterSubmit}>
                    {(states.filterModalType==='list') ? "Apply" : "Save"}
                </Button>,
            ]}
        >
            {states.filterModalType==='list'?
                <div className={"modal_title"}>
                    <Form.Item label={"Apply Filter From List"}>
                    <Select className={"single_select"} 
                            placeholder={"Select Filter"} 
                            onSelect={val=> setStates(prevVal=> ({...prevVal, filterName: val}))}
                            value={states.filterName}
                            showSearch
                            filterOption={(input,option)=>option.children.toLowerCase().includes(input)}
                    >

                        {views.map((val,index)=><Select.Option value={index} key={index}>{val?.name}</Select.Option>)}
                    </Select>
                        </Form.Item>
                </div>
                : <div className={"result_summary"}>
                    <div className="total_result">
                        <strong className={"total_count"}>Result summary:</strong> <span
                        className={"count_text"}>{count} {count > 1 ? "Products" : "Product"} </span>
                        {/*<span className={"parent_count"}>({parentCount} {parentCount > 1 ? "parents" : "parent"} and {variantsCount} {variantsCount > 1 ? "variations" : "variation"})</span>*/}
                    </div>
                    <div className="modify_btn">
                        <Button onClick={() => setStates(prevVal=> ({...prevVal, modify: true}))} className={states.modify ? "btn1 primary_btn" : "btn1 secondary_btn"}>Update existing filter</Button>
                        <Button onClick={() => setStates(prevVal=> ({...prevVal, modify: false}))}
                                className={!states.modify ? "primary_btn btn2" : "secondary_btn btn2"}>Create new filter</Button>
                    </div>
                    {/* <Form style={{marginTop:'1rem'}}> */}
                        {states.modify ? <>
                            <Form.Item className={"modal_title"} label={"Select filter to update"}>
                                    <Select className={"single_select"} placeholder={"Select filter"}
                                        onSelect={val => setStates(prevVal=> ({...prevVal, selectedFilterName:val}))}>
                                    {views.map((val, index) => <Select.Option value={index}
                                                                              key={index}>{val?.name}</Select.Option>)}
                                </Select>

                            </Form.Item>

                        </> : <Form.Item label="Add filter name" className="modal_title form_item">
                            <Input className={"single_select"} rootClassName={"custom-input-field"}  placeholder="Name" value={states.filterName} onChange={handleFilterChange}/>
                        </Form.Item>}

                    {/* </Form> */}
                </div>}
        </Modal>
    );

};

export default FilterModal;