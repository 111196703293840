export const FETCH_STAFF_LIST = 'staff/fetchStaffList'
export const CREATE_STAFF = 'staff/createStaff'
export const EDIT_STAFF = 'staff/editStaff'
export const DELETE_STAFF = 'staff/deleteStaff'
export const RESET_PASSWORD = 'staff/resetPassword'

export const FETCH_STAFF_LIST_FULFILLED = 'staff/fetchStaffList/fulfilled'
export const CREATE_STAFF_FULFILLED = 'staff/createStaff/fulfilled'
export const EDIT_STAFF_FULFILLED = 'staff/editStaff/fulfilled'
export const DELETE_STAFF_FULFILLED = 'staff/deleteStaff/fulfilled'
export const RESET_PASSWORD_FULFILLED = 'staff/resetPassword/fulfilled'

export const FETCH_STAFF_LIST_PENDING = 'staff/fetchStaffList/pending'
export const CREATE_STAFF_PENDING = 'staff/createStaff/pending'
export const EDIT_STAFF_PENDING = 'staff/editStaff/pending'
export const DELETE_STAFF_PENDING = 'staff/deleteStaff/pending'
export const RESET_PASSWORD_PENDING = 'staff/resetPassword/pending'

export const FETCH_STAFF_LIST_REJECTED = 'staff/fetchStaffList/rejected'
export const CREATE_STAFF_REJECTED = 'staff/createStaff/rejected'
export const EDIT_STAFF_REJECTED = 'staff/editStaff/rejected'
export const DELETE_STAFF_REJECTED = 'staff/deleteStaff/rejected'
export const RESET_PASSWORD_REJECTED = 'staff/resetPassword/rejected'