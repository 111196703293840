import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux'
import {BrowserRouter} from "react-router-dom";
import App from './App';
import './index.css';
import "./styles/dashboard.scss"
import "./styles/product_detail.scss"
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import reportWebVitals from './reportWebVitals';
// Store
import store from './redux/store/store'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
