import React from 'react';
import {Tabs} from 'antd';
import Attributes from './Attributes';
import AttributeGroup from './AttributeGroup';
import {useLocation, useNavigate} from "react-router-dom";


const AttributesDashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // const [activeKey, setActiveKey] = React.useState('1');
    // useEffect(() => {
    //     const activeTab = setActtiveTabFrom(location.pathname);
    //     if (activeTab) {
    //         setActiveKey(activeTab);
    //     }
    // }, [location.pathname]);

    const handleTabChange = (activeKey) => {
        if (activeKey === '1') {
            navigate('/attributes');
        } else if (activeKey === '2') {
            navigate('/attributes/group');
        }
    };

    const setActtiveTabFrom = (path) => {
        if (path === '/attributes') {
            return '1';
        } else if (path === '/attributes/group') {
            return '2';
        }
    };

    const top_navbar_tabs = () => {
        return (
            <div className="top_tabbar">
            <Tabs defaultActiveKey={setActtiveTabFrom(location.pathname)} rootClassName="custom_tabs" onChange={handleTabChange}
                items={[
                    {
                        label: `Attributes`,
                        key: '1',
                        children: <Attributes />
                    },
                    {
                        label: `Group`,
                        key: '2',
                        children: <AttributeGroup />
                    }

                ]}
            />
        </div>
        )
    }
    return (
        <div className="detail_right_wrapper detail_custom_width">
            {top_navbar_tabs()}            
        </div>
    );
};

export default AttributesDashboard;