import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncAction} from "../common/AsyncCases";
import AttributeThunk from "../thunk/AttributeThunk";

const initialState = {
    attributes: [],
    attributeGroupList: [],
    isLoading: false,
    error: null,
};

const attributeSlice = createSlice({
    name: 'attribute',
    initialState,
    reducers: {
        resetError: (state) => {
            state.error = null;
        },
        resetAttributesState: (state) => {
            state.attributes = [];
            state.attributeGroupList = [];
            state.isLoading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        handleAsyncAction(builder, AttributeThunk.fetchAttributes, (state, action) => {
            state.attributes = action.payload.attributes;
        });

        handleAsyncAction(builder, AttributeThunk.addAttribute, (state, {payload}) => {
            state.attributes = [payload.data.attributeData, ...state.attributes];

        });

        handleAsyncAction(builder, AttributeThunk.updateAttribute, (state, action) => {
            const index = state.attributes.findIndex((attribute) => attribute.id === action.payload.data.attributeData[0].id);
            state.attributes[index] =action.payload.data.attributeData[0];

        });

        handleAsyncAction(builder, AttributeThunk.fetchAtrributeDetails, (state, action) => {
            state.attributes.push(action.payload);
          state.attributes[state.attributes.length-1]=state.attributes[state.attributes.length-1];
        });
        handleAsyncAction(builder, AttributeThunk.deleteAttribute, (state, action) => {
            const index = state.attributes.findIndex((attribute) => attribute.id === action.payload.data.attributeId);
            state.attributes.splice(index, 1);
        });

        handleAsyncAction(builder, AttributeThunk.attributeGroupAdd, (state, action) => {
            state.attributeGroupList = [action.payload.data.attributeGroupData, ...state.attributeGroupList];
            // state.attributeGroupList.push(action.payload.data.attributeGroupData);
        });
        handleAsyncAction(builder, AttributeThunk.attributeGroupUpdate, (state, action) => {
            const index = state.attributeGroupList.findIndex((attributeGroup) => attributeGroup.id === action.payload.data.attributeGroupData[0].id);
            state.attributeGroupList[index] = action.payload.data.attributeGroupData[0];
        });
        handleAsyncAction(builder, AttributeThunk.attrbuteGroupList, (state, action) => {
            state.attributeGroupList = action.payload.data.attributeGroupList;
        });
        handleAsyncAction(builder, AttributeThunk.attributeGroupDelete, (state, action) => {
            const index = state.attributeGroupList.findIndex((attributeGroup) => attributeGroup.id === action.payload.data.attributeGroupId);
            state.attributeGroupList.splice(index, 1);
        });

    }
});

export const {resetError,resetAttributesState} = attributeSlice.actions;
export default attributeSlice.reducer;