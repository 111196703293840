import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select, Spin } from 'antd';
import EditProductModal from "./EditProductModal";

const { Option } = Select;
export default function EditProductV2({ 
    isAssetPage,
    beforeUpload,
    handleAssetUpload,
    setFileList,
    fileList,
    getData,
    attributeTypeMap,
    states,
    setStates
}) {
    const { attributes, attributeGroupList } = useSelector((state) => state.attributes);
    const { productDetail } = useSelector((state) => state.products);
    const [searchable, setSearchable] = useState([]);
    const [selected, setSelected] = useState(0);
    const [searched, setSearched] = useState([]);
    const [searchValue, setSearchValue] = useState("")
    useEffect(() => {
        /**
         * Function to arrange values according to values present
         * @returns {Array}
         * @param arr
         */
        const ArrangeArrayData = (arr) => {
            let valueArray = [];
            let nonValueArray = [];
            arr.map(val => {

                let temp = { ...val };
                if (isAssetPage) {
                    if (attributeTypeMap.get(val.name)?.type === 'media' && val.name!=='thumbnail') {
                        let found = attributeTypeMap.get(val.name);
                        if (found) {
                            temp.type = found.type;
                            temp.nameToShow = found.nameToShow;
                        }
                        if (productDetail[val.name]) {

                            valueArray.push(temp)
                        } else {
                            nonValueArray.push(temp);
                        }
                    }
                } else {
                    if ((val.name !== 'sku' && val.name !== 'label' &&
                        val.name !== 'product_name' &&
                        val.name !== 'parent_sku' &&
                        val.name !== 'updated_at' &&
                        val.name !== 'created_at' &&
                        val.name !== 'id' &&
                        val.name !== 'children' &&
                        val.name !== 'variation_of' &&
                        val.name !== 'status' &&
                        val.name !== 'is_variant')) {
                        let found = attributeTypeMap.get(val.name);
                        if (found) {
                            temp.type = found.type;
                            temp.nameToShow = found.nameToShow;
                        }
                        if (productDetail[val.name]) {

                            valueArray.push(temp)
                        } else {
                            nonValueArray.push(temp);
                        }
                    }
                }
                return val;
            });
            return [...[...valueArray.filter(val => val.name === 'price'), ...valueArray.filter(val => val.name === 'brand_name' || val.name === 'taxonomy_categories'), ...valueArray.filter(val => !(val.name === 'brand_name' || val.name === 'taxonomy_categories' || val.name === 'price'))], ...[...nonValueArray.filter(val => val.name === 'assets'), ...nonValueArray.filter(val => val.name === 'price'), ...nonValueArray.filter(val => val.name === 'brand_name' || val.name === 'taxonomy_categories'), ...nonValueArray.filter(val => !(val.name === 'brand_name' || val.name === 'taxonomy_categories' || val.name === 'price' || val.name === 'assets'))]];
        }


        /**
         * Function to set Group Data
         */
        const setGroups = () => {
            const custom = {
                "attributes": ArrangeArrayData(attributes),
                "label":
                    "All Attributes",
                "labelToShow":
                    "All Attributes",
            }
            let tempGroup = [];
            attributeGroupList.map(val => {
                let t = {};
                t['label'] = val['label'];
                t['labelToShow'] = val['labelToShow'];
                t['attributes'] = ArrangeArrayData(val.attributes);

                tempGroup.push(t);
                return val;
            });
            const myAttributesList = {
                "attributes": ArrangeArrayData(states.filter),
                "label": "Product Attributes",
                "labelToShow": "Product Attributes",
            }
            setSearchable([myAttributesList, custom, ...tempGroup]);
        }
        if (states.filter && attributes && attributeGroupList) {
            setGroups();
        }
        setSearchValue("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes, attributeGroupList, states.filter, isAssetPage])

    useEffect(() => {
        if (searchable && searchable.length) {
            if (searchable[selected]?.attributes && searchable[selected]?.attributes.length) {
                setSearched(searchable[selected]?.attributes);
            }
            else {
                if (selected === 0) {
                    let ind = searchable.findIndex(val => val.labelToShow === 'System Attributes');
                    if (ind !== -1) {
                        setSelected(ind);
                        setSearched(searchable[ind].attributes);
                    }
                } else {
                    setSelected(0);
                    setSearched(searchable[0]?.attributes);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchable]);

    const handleShowModal = (val) => {
        setSelected(val);
        if (searchable) setSearched(searchable[val].attributes)
        setSearchValue("");
    }

    /**
     * Function to Search Attribute in a group
     * @param e
     */
    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        let temp = searchable[selected].attributes.filter(val => val.nameToShow.toLowerCase().includes(e.target.value.toLowerCase()))
        setSearched(temp);
    }

    const SelectAttributeType = () => {
        return (
            <div className="attributes_wraper">
                <div className="attribute_top_Sec">
                    <div className="left_Sec">
                        <div className="attribute_Select_wrapper">
                            <label>Attribute Group</label>
                            <Select
                                showSearch
                                value={searchable[selected]?.labelToShow}
                                style={{ width: '100%', marginTop: '1rem' }}
                                placeholder="Select a Attribute/Group"
                                optionFilterProp="children"
                                className="attribute_select"
                                popupClassName={"attribute_dropdown_sec"}
                                onSelect={(val) => handleShowModal(val)}
                                filterOption={(input, option) => {
                                    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                }
                            >
                                {
                                    searchable && searchable.map((val, index) => {
                                        let temp = 0;
                                        val?.attributes?.map(val => {
                                            if (isAssetPage) {
                                                temp += val.type === 'media' && (val.nameToShow !== 'Thumbnail' && val.nameToShow !== 'thumbnail');
                                            } else {
                                                temp += val.type !== 'media';
                                            }
                                            return val;
                                        });
                                        return <Option key={val.labelToShow}
                                            value={index}>
                                            <span className={"count"}>{temp} </span>
                                            <span className={"text"}> {val.labelToShow}</span>

                                        </Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className="grid_view"></div>
                    </div>
                    <div className="right_sec">
                        <div className="search_box border_search">
                            <input type="text"
                                value={searchValue}
                                placeholder={`Search by ${isAssetPage ? 'asset' : 'attribute'} name`}
                                onChange={handleSearch}
                            />
                            <span className="search_icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                                        stroke="#969696" strokeWidth="1.6" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                </svg>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
    const AttributeDetailCard = () => {
        return (
            <div className="attribute_detail_card">
                {!!searched?.length && searched.map(val => {
                    let ValBody = states.body?.find(value => val.name === value.name);
                    let value = (productDetail[val.name] || productDetail[val.name] === 0 || productDetail[val.name] === false) ? productDetail[val.name] : null;
                    let type = attributeTypeMap.get(val.name);
                    return <EditProductModal
                        key={val.nameToShow}
                        Active={((ValBody?.value || ValBody?.value === 0 || ValBody?.value === false) && ValBody.value !== "") || ((value || value === false || value === 0) && value !== "")}
                        val={type}
                        isAssetPage={isAssetPage}
                        data={val}
                        states = {states}
                        setStates = {setStates}
                        productData={productDetail}
                        attributesMap={attributeTypeMap}
                        attrbuteGroupList={attributeGroupList}
                        handleAssetUpload={handleAssetUpload}
                        beforeUpload={beforeUpload}
                        fileList={fileList}
                        setFileList={setFileList}
                        getData={getData}
                    />

                })}
            </div>
        )
    }
    return (
        <Spin spinning={false} >
            {SelectAttributeType()}
            {AttributeDetailCard()}
        </Spin>
    )
}
