import api from "../../config/api";

const staffService = {
    fetchStaffList: async (body) => {
        return await api.post('/staff/list',{...body});
    },

    createStaff: async (newStaff) => {
        return await api.post('/staff/add', {...newStaff});
    },

    editStaff: async (editedStaff) => {
        return await api.put(`/staff/edit`, {...editedStaff});
    },

    deleteStaff: async (staffId) => {
        return await api.delete(`/staff/delete?id=${staffId}`);
    },

    resetPasswrord: async (staff) => {
        return await api.put(`/staff/resetPassword`,{...staff});
    }

};
export default staffService;