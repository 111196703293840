import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncAction} from "../common/AsyncCases";
import viewThunk from "../thunk/ViewThunk";

const initialState = {
    views: [],
    isLoading: false,
    count: 0,
    error: null,
};

const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {
        resetError: (state) => {
            state.error = null;
        },
        resetViewState: (state) => {
            state.views = [];
            state.isLoading = false;
            state.count = 0;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        handleAsyncAction(builder, viewThunk.listView, (state, action) => {
            state.views = action.payload.data.viewList;
            state.count = action.payload.data.totalCount;
            }
        );

        handleAsyncAction(builder, viewThunk.addView, (state, action) => {
            state.views = [action.payload.data?.addedView, ...state.views];
        });

        handleAsyncAction(builder, viewThunk.editView, (state, action) => {
            state.views = state.views.map((view) =>
                view.id === action.payload?.data?.updatedView?.id ? action.payload.data?.updatedView : view
            );
        });

        handleAsyncAction(builder, viewThunk.deleteView, (state, action) => {
            // const index = state.views.findIndex((view) => view.id === action.payload.data.viewId);
            // state.views.splice(index, 1);
        });

        handleAsyncAction(builder, viewThunk.setDefaultView, (state, action) => {
            // state.views = state.views.map((view) =>
            //     view.id === action.payload.data.id ? action.payload.data : view
            // );
        });

    }
});

export const {resetViewState} = viewSlice.actions;

export default viewSlice.reducer;




