import {createSlice} from '@reduxjs/toolkit';
import {fetchStaffList, createStaff, editStaff, deleteStaff , resetPasswordStaff} from '../thunk/StaffThunk';
import {handleAsyncAction} from "../common/AsyncCases";

const initialState = {
    staffList: [],
    count: 0,
    isLoading: false,
    error: null,
};
const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        resetStaffState: (state) => {
            state.staffList = [];
            state.count = 0;
            state.isLoading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        handleAsyncAction(builder, fetchStaffList, (state, action) => {
            state.staffList = action.payload.staffList;
            state.count = action.payload.count;
        });

        handleAsyncAction(builder, createStaff, (state, action) => {
            // Add the new staff member to the state
            const newStaff = action.payload.data;
            state.staffList = [newStaff, ...state.staffList];
        });

        handleAsyncAction(builder, editStaff, (state, action) => {
            // Update the state with the edited staff member
            const editedStaff = action.payload;
            state.staffList = state.staffList.map((staff) =>
                staff.id === editedStaff.id ? editedStaff : staff
            );
        });

        handleAsyncAction(builder, deleteStaff, (state, action) => {
            // Remove the deleted staff member from the state
            const staffId = action.payload;
            state.staffList = state.staffList.filter((staff) => staff.id !== staffId);
        });

        handleAsyncAction(builder, resetPasswordStaff, (state, action) => {
            // Remove the deleted staff member from the state
            // const staffId = action.payload;
            // state.staffList = state.staffList.filter((staff) => staff.id !== staffId);
        });

    }
});
export const {resetStaffState} = staffSlice.actions;
export default staffSlice.reducer;