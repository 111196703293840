export const FETCH_PRODUCT = 'product/fetchProducts';
export const ADD_PRODUCT = 'product/addProduct';
export const IMPORT_PRODUCT_CSV = 'product/importProductCsv';
export const PRODUCT_LIST = 'product/productList';
export const PRODUCT_DETAIL = 'product/productDetail';
export const PRODUCT_SKU_VALIDATION = 'product/productSkuValidation';
export const VARIANT_UNLINK = 'product/variantUnlink';
export const PRODUCT_DELETE = 'product/productDelete';
export const PRODUCT_EDIT = 'product/productEdit';
export const EDIT_COLUMN_PRODUCT_LIST = 'product/editColumnsProductList';
export const PRODUCT_BULK_DELETE = 'product/productBulkDelete';   
export const PRODUCT_ASSET_UPLOAD = 'product/productAssetUpload';
export const PRODUCT_ASSET_DELETE = 'product/productAssetDelete';
export const PRODUCT_FILTER_SAVE = 'product/filterSave';


export const FETCH_PRODUCT_FULFILLED = 'product/fetchProducts/fulfilled';
export const ADD_PRODUCT_FULFILLED = 'product/addProduct/fulfilled';
export const IMPORT_PRODUCT_CSV_FULFILLED = 'product/importProductCsv/fulfilled';
export const PRODUCT_LIST_FULFILLED = 'product/productList/fulfilled';
export const PRODUCT_DETAIL_FULFILLED = 'product/productDetail/fulfilled';
export const PRODUCT_SKU_VALIDATION_FULFILLED = 'product/productSkuValidation/fulfilled';
export const VARIANT_UNLINK_FULFILLED = 'product/variantUnlink/fulfilled';
export const PRODUCT_DELETE_FULFILLED = 'product/productDelete/fulfilled';
export const PRODUCT_EDIT_FULFILLED = 'product/productEdit/fulfilled';
export const EDIT_COLUMN_PRODUCT_LIST_FULFILLED = 'product/editColumnsProductList/fulfilled';
export const PRODUCT_BULK_DELETE_FULFILLED = 'product/productBulkDelete/fulfilled';   
export const PRODUCT_ASSET_UPLOAD_FULFILLED = 'product/productAssetUpload/fulfilled';
export const PRODUCT_ASSET_DELETE_FULFILLED = 'product/productAssetDelete/fulfilled';
export const PRODUCT_FILTER_SAVE_FULFILLED = 'product/filterSave/fulfilled';

export const FETCH_PRODUCT_PENDING = 'product/fetchProducts/pending';
export const ADD_PRODUCT_PENDING = 'product/addProduct/pending';
export const IMPORT_PRODUCT_CSV_PENDING = 'product/importProductCsv/pending';
export const PRODUCT_LIST_PENDING = 'product/productList/pending';
export const PRODUCT_DETAIL_PENDING = 'product/productDetail/pending';
export const PRODUCT_SKU_VALIDATION_PENDING = 'product/productSkuValidation/pending';
export const VARIANT_UNLINK_PENDING = 'product/variantUnlink/pending';
export const PRODUCT_DELETE_PENDING = 'product/productDelete/pending';
export const PRODUCT_EDIT_PENDING = 'product/productEdit/pending';
export const EDIT_COLUMN_PRODUCT_LIST_PENDING = 'product/editColumnsProductList/pending';
export const PRODUCT_BULK_DELETE_PENDING = 'product/productBulkDelete/pending';   
export const PRODUCT_ASSET_UPLOAD_PENDING = 'product/productAssetUpload/pending';
export const PRODUCT_ASSET_DELETE_PENDING = 'product/productAssetDelete/pending';
export const PRODUCT_FILTER_SAVE_PENDING = 'product/filterSave/pending';

export const FETCH_PRODUCT_REJECTED = 'product/fetchProducts/rejected';
export const ADD_PRODUCT_REJECTED = 'product/addProduct/rejected';
export const IMPORT_PRODUCT_CSV_REJECTED = 'product/importProductCsv/rejected';
export const PRODUCT_LIST_REJECTED = 'product/productList/rejected';
export const PRODUCT_DETAIL_REJECTED = 'product/productDetail/rejected';
export const PRODUCT_SKU_VALIDATION_REJECTED = 'product/productSkuValidation/rejected';
export const VARIANT_UNLINK_REJECTED = 'product/variantUnlink/rejected';
export const PRODUCT_DELETE_REJECTED = 'product/productDelete/rejected';
export const PRODUCT_EDIT_REJECTED = 'product/productEdit/rejected';
export const EDIT_COLUMN_PRODUCT_LIST_REJECTED = 'product/editColumnsProductList/rejected';
export const PRODUCT_BULK_DELETE_REJECTED = 'product/productBulkDelete/rejected';   
export const PRODUCT_ASSET_UPLOAD_REJECTED = 'product/productAssetUpload/rejected';
export const PRODUCT_ASSET_DELETE_REJECTED = 'product/productAssetDelete/rejected';
export const PRODUCT_FILTER_SAVE_REJECTED = 'product/filterSave/rejected';

