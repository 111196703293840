import {FETCH_STAFF_LIST_PENDING} from '../types/StaffActionTypes';
import {FETCH_ATTRIBUTES_PENDING,} from '../types/AttributeActionTypes';
import {ACCESS_TOKEN_LOGIN_REJECTED} from "../types/UserActionTypes"
import {PRODUCT_LIST_PENDING} from "../types/ProductActionTypes"

export const handleAsyncAction = (builder, asyncAction, fulfilledCallback) => {
    builder
        .addCase(asyncAction.pending, (state,action) => {
            if((action.type === FETCH_STAFF_LIST_PENDING && state.staffList.length > 0) ||
                (action.type === FETCH_ATTRIBUTES_PENDING && state.attributes.length > 0) ||
                //product
                (action.type === PRODUCT_LIST_PENDING && state.products.length > 0)

            ){
                state.isLoading = false;
                return
            }
            state.isLoading = true;
            state.isVerified = null;
            state.error = null;
        })
        .addCase(asyncAction.fulfilled, (state, action) => {
            state.isLoading = false;
            if (fulfilledCallback) {
                fulfilledCallback(state, action);
                state.error = null;
            }
        })
        .addCase(asyncAction.rejected, (state, action) => {

            if(action.type === ACCESS_TOKEN_LOGIN_REJECTED){
                state.user = null;
                state.error = null;
                state.isLoading = false;
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                return
            }
            state.isLoading = false;
            state.isVerified = false;
            state.error = action.error.message;
        });
};

