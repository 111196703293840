//Modules
import {Routes, Route, Navigate} from "react-router-dom"
import React from "react";

// Auth
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ResetPassword from "./pages/Auth/ResetPassword";
// Staff
import StaffList from "./pages/staff/dashboard/StaffList";
//Product
import AddProduct from "./pages/company/Product/AddProduct";
import ImportProduct from "./pages/company/Product/ImportProduct";
import ProductList from "./pages/company/Product/ListContainer/ProductList";
import ProductDeatils from "./pages/company/Product/DetailContainer/ProductDetail";
//Attributes
import AttributesDashboard from "./pages/company/Settings/AttributesDashboard";
import AccountDashboard from "./pages/staff/dashboard/AccountDashboard";
import Categories from "./pages/company/Settings/Categories";
import Views from "./pages/views/EditColumns";
import RealationShip from "./pages/company/Settings/RelationShip";
//Private Routes
import StaffPrivateRoute from "./components/layout/staff/StaffPrivateRoute";
import AdminSettingsPrivateRoute from "./components/layout/admin/AdminSettingsPrivateRoute";
import ProfilePrivateRoute from "./components/layout/admin/ProfilePrivateRoute";
import Error404 from "./pages/ErrorPages/Error404";
//Components
import TopNav from "./components/header/TopNav";
import ErrorBoundary from "./components/Error/ErrorBoundary";
// Styles
import './App.css';

import { useSelector} from "react-redux";


function App() {
    const {user} = useSelector((state) => state.user);

    return (
        <div>
            <TopNav/>
            <div className="dashboard_wrapper">
             <ErrorBoundary>
            <Routes>
                {/*<Route path="/" exact element={<Login/>}/>*/}
                <Route path="/" element={user ? <Navigate to="/product/list" /> : <Login />} />
                {/*<Route path="/home" exact element={<Home/>}/>*/}
                <Route path="/login" element={user ? <Navigate to="/product/list" /> : <Login />} />
                <Route path="/auth/create-password" element={user ? <Navigate to="/product/list" /> : <ResetPassword />} />
                <Route path="/register" element={user ? <Navigate to="/dashboard" /> : <Register />} />
                {/*<Route path="/dashboard" exact element={<StaffPrivateRoute component={StaffHome} />} />*/}
                <Route path="/account" exact element={<ProfilePrivateRoute component={AccountDashboard} />} />
                <Route path="/staff/list" exact element={<ProfilePrivateRoute component={StaffList} />} />
                <Route path="/product/add" exact element={<StaffPrivateRoute component={AddProduct} />} />
                <Route path="/product/import" exact element={<AdminSettingsPrivateRoute component={ImportProduct} />} />
                <Route path="/product/list" exact element={<StaffPrivateRoute component={ProductList} />} />
                <Route path="/product/details" exact element={<StaffPrivateRoute component={ProductDeatils} />} />
                {/*<Route path="/product/edit/v2" exact element={<StaffPrivateRoute component={EditProductV2} />} />*/}
                <Route path="/attributes" exact element={<AdminSettingsPrivateRoute component={AttributesDashboard} />} />
                <Route path="/attributes" exact element={<AdminSettingsPrivateRoute component={AttributesDashboard} />} />
                <Route path="/attributes/group" exact element={<AdminSettingsPrivateRoute component={AttributesDashboard} />} />
                <Route path="/categories" exact element={<AdminSettingsPrivateRoute component={Categories} />} />
                <Route path="/relationship" exact element={<AdminSettingsPrivateRoute component={RealationShip} />} />
                <Route path="/views" exact  element={<Views/>} />
                <Route path="/404" exact element={<Error404/>}/>
                <Route path="*" element={<Navigate to={"/404"}/>}/>
            </Routes>
            </ErrorBoundary>
            </div>
        </div>

    );
}

export default App;
