import {createAsyncThunk} from '@reduxjs/toolkit';
import userService from '../actions/UserAction';
import  {REGISTER,LOGIN,LOGOUT,ACCESS_TOKEN_LOGIN,VALIDATE_KEY,RESET_PASSWORD}
from '../types/UserActionTypes'
export const registerUser = createAsyncThunk(REGISTER, async (newUser) => {
    try {
        const userData = await userService.register(newUser);
        return userData?.data?.data;
    } catch (e) {
        throw e?.response?.data || e.message;
    }
});
export const loginUser = createAsyncThunk(LOGIN, async ({email, password}) => {
    try {
        const userData = await userService.login({email, password});
        return userData?.data?.data;
    } catch (e) {
        console.log(e)
        throw e?.response?.data || e.message;
    }
});

export const logoutUser = createAsyncThunk(LOGOUT, async () => {
    try {
        const userData = await userService.logout();
        return userData?.data?.data;
    } catch (e) {
        throw e?.response?.data || e.message;
    }

});

export const accessTokenLoginUser = createAsyncThunk(ACCESS_TOKEN_LOGIN, async () => {
    try {
        const userData = await userService.accessTokenLogin();
        return userData?.data?.data;
    } catch (e) {
        throw e.message;
    }
});


export const validateKey = createAsyncThunk(VALIDATE_KEY, async (key) => {
    try {
        const userData = await userService.validateKey(key);
        return userData?.data?.data;
    } catch (e) {
        throw e.message;
    }
});

export const resetPassword = createAsyncThunk(RESET_PASSWORD, async (password) => {
    try {
        const userData = await userService.resetPassword(password);
        return userData?.data?.data;
    } catch (e) {
        throw e.message;
    }
});