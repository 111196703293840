import api from '../../config/api';

const userService = {
    register: async (newUser) => {
        return await api.post('/company/signup', {...newUser});
    },
    login: async ({email, password}) => {
        return await api.post('/staff/login', {email, password});

    },
    logout: async () => {
        return await api.post('/staff/logout');
    },
    accessTokenLogin: async () => {
        return await api.post('/staff/accessTokenLogin')
    },

    validateKey : async (key) => {
        return await api.get(`/validate/key?key=${key}`)
    },

    resetPassword : async (password) => {
        return await api.put('/reset/password/key', {...password})
    }
};

export default userService;
