export function capitalizeFirstLetter(str) {
    // Check if the input is a valid string
    if (typeof str !== 'string' || str.length === 0) {
      return str; // Return the input unchanged
    }
  
    // Capitalize the first letter and concatenate the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

export function arraysOfArraysOfObjectsAreEqual(arr1, arr2) {
    // Check if both arrays are of equal length
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Iterate through each element of the arrays
    for (let i = 0; i < arr1.length; i++) {
        const innerArr1 = arr1[i];
        const innerArr2 = arr2[i];

        // Check if both inner arrays are of equal length
        if (innerArr1.length !== innerArr2.length) {
            return false;
        }

        // Iterate through each element of the inner arrays
        for (let j = 0; j < innerArr1.length; j++) {
            const obj1 = innerArr1[j];
            const obj2 = innerArr2[j];

            // Compare objects by their properties
            if (!objectsAreEqual(obj1, obj2)) {
                return false;
            }
        }
    }

    // If all elements are equal, return true
    return true;
}

function objectsAreEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
    return true;
}

export function toProperCase(text) {
    // Replace underscores with spaces
    const stringWithSpaces = text?.replace(/_/g, ' ');
    // convert this to number '123' => 123
    // remove all non-word characters
    const stringWithSpacesNumber = stringWithSpaces?.replace(/\W/g, '');
    // if(!isNaN(stringWithSpacesNumber)) return Number(stringWithSpacesNumber);

    if (/^\d+$/.test(stringWithSpacesNumber)) {
        return Number(stringWithSpacesNumber);
    }

    // return stringWithSpacesNumber;

    // Capitalize the first letter of each word
    const words = stringWithSpaces.split(' ');
    const properCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together
    const properCaseText = properCaseWords.join(' ');
    if(properCaseText.toLowerCase()==="sku") {
        return "SKU";
    }
    if(properCaseText.toLowerCase()==="parent sku") {
        return "Parent SKU";
    }
    if(properCaseText==="Taxonomy Categories") {
        return "Taxonomy/Categories";
    }

    return properCaseText;
}