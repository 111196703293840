import axios from 'axios';
import config from '../config/config';

const api = axios.create({
    baseURL: config.baseApiUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Set Authorization header on initial load
const token = localStorage.getItem('token');
if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
} else {
    api.defaults.headers.common['Authorization'] = null;
}

// Add request interceptor
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add response interceptor
api.interceptors.response.use(
    (response) => {
        // Do something with the response data
        return response;
    },
    (error) => {
        // Do something with the response error
        let res = error.response ;
        if (res && res.status === 401 && res.config && !res.config.__isRetryRequest) {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            // navigate("/login");
            window.location.href = '/login'; // Redirect to login
        }
        return Promise.reject(error);
    }
);

export default api;
