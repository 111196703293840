// StaffModal.js
import React from 'react';
import { Modal, Button } from 'antd';

const StaffModal = ({ visible, onCancel, onSubmit, title, content, extraFooter }) =>{
    return  <Modal
        title={title}
        open={visible}
        onCancel={onCancel}
        className={title === "Delete Staff" || title === "Reset Password" ? " custom_modal modal_md ":" edit_staff_modal custom_modal"}
        footer={[
            <Button key="back" className="secondary_btn" onClick={onCancel}>
                Cancel
            </Button>,
            <Button key="submit" type="primary"  className="primary_btn" onClick={onSubmit}>
                {title === 'Delete Staff' ? 'Confirm Delete' : 'Submit'}
            </Button>,
            ...(extraFooter || []), // Additional buttons specified by the calling component
        ]}
    >
        <div className={"delete_modal_wrap"}>
            {content}
        </div>
    </Modal>
}

export default StaffModal;
