import React from 'react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { Button, Input, Row, Col } from 'antd';
import PhoneInput from 'react-phone-input-2';

const AuthForm = ({ initialValues, validationSchema, onSubmit, fields, buttonText }) => {


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Row gutter={[16, 16]}>
                        {fields.map((field) => (
                            <Col span={24} key={field.name}>
                                {field.type === 'phone' ? (
                                    <Field name={field.name} component='span'>
                                        {({ field, form }) => (
                                            <>
                                            <PhoneInput
                                                className="phone ant-input"
                                                country="us"
                                                placeholder={field.name === 'contactNumber' ? 'Enter phone number' : ''}
                                                value={field.state || undefined}
                                                onChange={(value) => {
                                                    if (value && value.toString().length <= 13) {
                                                        form.setFieldValue(field.name, value.toString());
                                                    }
                                                }}
                                                
                                            /> <ErrorMessage name={field.name} component="div" style={{ color: 'red' }} />
                                            </>
                                        )}
                                    </Field>
                                ) : (
                                    <>
                                        <Field as={Input} type={field.type} name={field.name} placeholder={field.placeholder} />
                                        <ErrorMessage name={field.name} component="div" style={{ color: 'red' }} />
                                    </>
                                )}
                            </Col>
                        ))}
                        <Col span={24}>
                            <Button type="primary" className="primary_btn" htmlType="submit" disabled={isSubmitting}>
                                {buttonText}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};


export default AuthForm;
