import {createAsyncThunk} from '@reduxjs/toolkit';
import viewService from "../actions/ViewAction";
import {LIST_VIEW,ADD_VIEW,EDIT_VIEW,DELETE_VIEW,SET_DEFAULT_VIEW} from'../types/ViewActionTypes';
const ViewThunk = {

listView: createAsyncThunk(LIST_VIEW, async () => {
        try {
            const response = await viewService.listView();
            return response.data;
        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    addView: createAsyncThunk(ADD_VIEW, async (newView) => {
        try {
            const response = await viewService.addView(newView);
            return response.data;
        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    editView: createAsyncThunk(EDIT_VIEW, async (view) => {
        try {
            const response = await viewService.editView(view);
            return response.data;
        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    deleteView: createAsyncThunk(DELETE_VIEW, async (viewId) => {
        try {
            const response = await viewService.deleteView(viewId);
            return response.data;
        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    setDefaultView: createAsyncThunk(SET_DEFAULT_VIEW, async (viewId) => {
        try {
            const response = await viewService.setDefaultView(viewId);
            return response.data;
        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

};


export default ViewThunk;



