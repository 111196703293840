
import { notification } from 'antd';


const Toast = {
    errorNotification: (description,duration=1.5) => {
        notification.error({
            message: "An Error Occured!!",
            description,
            duration: duration
        });
    },
    successNotification: (description,duration=1.5) => {
        notification.success({
            message: "Success",
            description,
            duration: duration
        })
    }


};
export default Toast;